<template>
  <q-dialog v-model="showErrorScreen" persistent maximized>
    <div class="error-screen q-pa-md flex justify-center items-center">
      <div
        class="general-content-default flex justify-center items-center column"
      >
        <div class="text-h4 text-bold text-center">Ops! Algo deu errado :(</div>

        <div
          class="text-body2 text-center text-grey-7 q-mt-md"
          style="max-width: 500px"
        >
          Por favor, tente novamente e se o problema persistir, entre em contato
          com o responsável.
        </div>

        <div class="update-content flex row items-center q-pa-sm q-mt-md">
          <div class="text-white flex items-center">
            Atualizando em:

            <span class="update-countdown q-ml-sm text-bold">
              0:{{ countdown ? countdown.toString().padStart(2, '0') : '00' }}
            </span>
          </div>

          <q-btn
            label="Atualizar"
            icon="refresh"
            class="update-button q-ml-md"
            flat
            color="white"
            @click="reloadPage()"
          />
        </div>

        <lottie :options="errorAnimation" autoplay loop />

        <div class="text-body2 text-center text-grey-7 q-mt-md">
          Registro de erro: Cód.
          <span class="cursor-pointer" @click="showError()">{{
            error.response.status
          }}</span>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { Notify } from 'quasar'

import { useErrorHandlingStore } from 'src/stores/ErrorHandlingStore'

import Lottie from 'src/components/LottieComponent.vue'

import * as lottieError from 'src/assets/lottie/error.json'

export default defineComponent({
  name: 'ErrorScreen',

  components: {
    Lottie,
  },

  data() {
    return {
      errorAnimation: { animationData: lottieError.default },
      countdown: 30,
      intervalId: null,
    }
  },

  computed: {
    ...mapState(useErrorHandlingStore, ['error', 'showErrorScreen']),
  },

  created() {
    this.startCountdown()
  },

  beforeUnmount() {
    clearInterval(this.intervalId)
  },

  methods: {
    startCountdown() {
      if (!this.showErrorScreen) {
        return
      }

      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        }

        if (this.countdown === 0) {
          this.reloadPage()
        }
      }, 1000)
    },

    reloadPage() {
      clearInterval(this.intervalId)
      window.location.reload(true)
    },

    showError() {
      Notify.create({
        message: this.error.response.data.message,
        color: 'negative',
        position: 'top',
        icon: 'error',
      })
    },
  },
})
</script>

<style scoped lang="scss">
.error-screen {
  background-color: #fff;

  .update-content {
    background-color: #2681fa;
    border-radius: 6px;
    width: 350px;

    .update-countdown {
      background-color: #fff;
      border-radius: 30px;
      padding: 4px 10px;
      color: #2681fa;
    }
  }
}

@media (max-width: 600px) {
  .error-screen {
    .update-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 220px;

      .update-button {
        margin-left: 0;
        margin-top: 6px;
      }
    }
  }
}
</style>
